import { orderBy } from "lodash";
import { toFormat } from "utils/date";
import { statusOptions } from "./constants";
import { stageOptions } from "utils/globalConsts";
import { admin_talent_project as $ } from "strings";

export const lastUpdatedFormatted = (project_last_updated) => {
  if (!project_last_updated) return $.unknown_value;

  const date = new Date(project_last_updated);
  const dateStr = toFormat(date, "MM/dd/yyyy hh:mm a");
  const timeZone =
    new Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
      .formatToParts(date)
      .find((part) => part.type === "timeZoneName")?.value || "";

  return `${dateStr} ${timeZone}`;
};

export const projectStageFormatted = (project_stage) => {
  return (
    stageOptions.find((o) => o.value === project_stage)?.label ||
    $.unknown_value
  );
};

export const candidateStatusFormatted = (status) => {
  return (
    statusOptions.find((o) => o.value === status)?.label || $.unknown_value
  );
};

export const groupProjectsByStatus = (projects) => {
  const grouped = {
    candidate: [],
    active: [],
    completed: [],
    rejected: [],
    inactive: []
  };

  const ordered = orderBy(projects, "project_name", "asc");

  ordered.forEach((p) => {
    // disallowed stages for FTE projects
    const fteDisallowedProjectStages = [
      "passive",
      "lost",
      "completed"
    ];
    // disallowed stages for all other projects
    const cpDisallowedProjectStages = [
      "passive",
      "filled",
      "lost",
      "completed"
    ];
    const cpDisallowedCandidateStatuses = ["placed", "pass"];
    if (p.status === "pass") {
      grouped["rejected"].push(p);
    } else if (p.status === "placed" && p.project_stage === "filled") {
      grouped["active"].push(p);
    } else if (p.status === "placed" && p.project_stage === "completed") {
      grouped["completed"].push(p);
    } else if (
      !cpDisallowedCandidateStatuses.includes(p.status) && (
        p.qb_class === 8 ? !fteDisallowedProjectStages.includes(p.project_stage) : !cpDisallowedProjectStages.includes(p.project_stage)
      )
    ) {
      grouped["candidate"].push(p);
    } else {
      grouped["inactive"].push(p);
    }
  });

  return grouped;
};
